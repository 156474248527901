import { ReactElement } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useSetAtom } from 'jotai/index';
import { useNavigate } from 'react-router-dom';
import { errorAtom } from 'common/atoms/error.atom.tsx';
import useErrorHandlerHook from 'common/hooks/useErrorHandler.hook.tsx';
import { authLoginService } from 'domains/auth/services/auth.services.ts';
import { SContainer, SLoginContainer, STitle } from 'pages/sign-in/sign-in.page.styles.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';

const SignInPage = (): ReactElement => {
  const navigate = useNavigate();
  const { errorHandler } = useErrorHandlerHook();
  const setErrorData = useSetAtom(errorAtom);

  return (
    <SContainer>
      <SLoginContainer>
        <STitle>Sign In to Driver Config Manager</STitle>
        <GoogleLogin
          type="standard"
          theme="outline"
          size="large"
          shape="rectangular"
          onSuccess={async (credentialResponse): Promise<void> => {
            setErrorData({
              message: '',
              description: '',
              action: <div />,
            });

            try {
              await authLoginService(credentialResponse);
            } catch (error) {
              errorHandler(error as Error);
              console.error(error);
            }
          }}
          onError={(): void => {
            console.error('Login error!!!');
            navigate(ROUTES_MAPPING.ERROR);
          }}
        />
      </SLoginContainer>
    </SContainer>
  );
};

export default SignInPage;
