import { Button } from 'antd';
import { useSetAtom } from 'jotai';
import { errorAtom } from 'common/atoms/error.atom.tsx';
import { queryClient } from 'common/clients/query.client.ts';
import AccessDeniedForm from 'common/components/alert/forms/access-denied.form.tsx';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';
import { authLogoutService } from 'domains/auth/services/auth.services.ts';
import { AWS_ROLES, AWS_ROLES_MAPPING } from 'domains/aws/constants/aws-roles.constants.ts';

enum EErrorName {
  ExpiredTokenException = 'ExpiredTokenException',
  IDPCommunicationErrorException = 'IDPCommunicationErrorException',
  IDPRejectedClaimException = 'IDPRejectedClaimException',
  InvalidIdentityTokenException = 'InvalidIdentityTokenException',
  MalformedPolicyDocumentException = 'MalformedPolicyDocumentException',
  PackedPolicyTooLargeException = 'PackedPolicyTooLargeException',
  RegionDisabledException = 'RegionDisabledException',
  AccessDenied = 'AccessDenied',
}

const useErrorHandlerHook = (): {
  errorHandler: (error: Error) => void;
} => {
  const setErrorData = useSetAtom(errorAtom);
  const SUPPORT_SLACK_CHANNEL = 'https://armenotech.slack.com/archives/C078HKN222V';

  const errorHandler = (error: Error): void => {
    const errorName = error.name as EErrorName;
    const user = JSON.parse(localStorage.getItem(ELocalStorage.User) ?? '') as { exp: number };
    const googleTokenExpTime = user.exp;
    const currentTime = new Date().getTime();

    switch (errorName) {
      case EErrorName.ExpiredTokenException:
      case EErrorName.InvalidIdentityTokenException:
        if (currentTime >= googleTokenExpTime) {
          setErrorData({
            message: 'Session was expired',
            description: 'Please login again',
            action: <div />,
          });

          authLogoutService()
            .then()
            .catch((error: unknown) => {
              console.error(error);
            });
        } else {
          queryClient
            .invalidateQueries({
              queryKey: ['aws-init-group'],
            })
            .then(() => {
              setErrorData({
                message: 'Session was refreshed',
                description: 'Please try again',
                action: <div />,
              });
            })
            .catch((error: unknown) => {
              console.error(error);
            });
        }
        break;
      case EErrorName.MalformedPolicyDocumentException:
        setErrorData({
          message: 'Unable to authorize your request',
          description: error.message,
          action: (
            <Button
              onClick={() => {
                window.open(SUPPORT_SLACK_CHANNEL, '_blank');
              }}
            >
              Contact Support
            </Button>
          ),
        });
        break;
      case EErrorName.PackedPolicyTooLargeException:
        setErrorData({
          message: 'Unable to authorize your request: RegionDisabledException',
          description: error.message,
          action: (
            <Button
              onClick={() => {
                window.open(SUPPORT_SLACK_CHANNEL, '_blank');
              }}
            >
              Contact Support
            </Button>
          ),
        });
        break;
      case EErrorName.RegionDisabledException:
        setErrorData({
          message: 'Unable to authorize your request: RegionDisabledException',
          description: error.message,
          action: (
            <Button
              onClick={() => {
                window.open(SUPPORT_SLACK_CHANNEL, '_blank');
              }}
            >
              Contact Support
            </Button>
          ),
        });
        break;
      case EErrorName.AccessDenied: {
        const accessDeniedError = error as Error & { role: keyof typeof AWS_ROLES };
        const description = error.message.replace(/sts:\w+/g, `"${AWS_ROLES_MAPPING[accessDeniedError.role].label}"`);

        setErrorData({
          message: error.name,
          description,
          action: <AccessDeniedForm error={error as Error & { role: keyof typeof AWS_ROLES }} />,
          durationOff: true,
        });
        break;
      }
      default:
        setErrorData({
          message: `Error: ${error.name}`,
          description: error.message,
          action: <div />,
        });
        break;
    }
  };

  return {
    errorHandler,
  };
};

export default useErrorHandlerHook;
