import { ReactElement } from 'react';
import { atom } from 'jotai';

interface ErrorState {
  message: string;
  description: string;
  action: ReactElement;
  durationOff?: boolean | undefined;
}

export const errorAtom = atom<ErrorState>({
  message: '',
  description: '',
  action: <div />,
});
