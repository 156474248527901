import React, { ReactElement } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input } from 'antd';
import { EDrawerType } from 'pages/configurations-group/enums/configurations-groups.enums.ts';
import { IConfigGroupDrawerData } from 'pages/configurations-group/interfaces/configurations-groups.interfaces.ts';

interface IProps {
  setFilterGroupNameValue: (entity: string) => void;
  setConfigGroupDrawerData: (entity: IConfigGroupDrawerData | Record<string, never>) => void;
}

const ConfigurationGroupSearch = (props: IProps): ReactElement => {
  const { setFilterGroupNameValue, setConfigGroupDrawerData } = props;

  const searchByGroupNameHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterGroupNameValue(event.target.value.toLowerCase());
  };

  return (
    <Flex justify="space-between" gap={24} style={{ paddingBottom: 24 }}>
      <Input
        placeholder="Search by group name"
        size="large"
        style={{
          maxWidth: 400,
        }}
        onChange={searchByGroupNameHandler}
        suffix={<SearchOutlined />}
        allowClear
      />
      <Button
        type="primary"
        size="large"
        icon={<PlusOutlined />}
        onClick={() => {
          setConfigGroupDrawerData({ type: EDrawerType.AddNew });
        }}
      >
        Add new provider
      </Button>
    </Flex>
  );
};

export default ConfigurationGroupSearch;
