import {
  VITE_AWS_ROLE_ARN_CONFIG_ENTRY_READER,
  VITE_AWS_ROLE_ARN_CONFIG_ENTRY_WRITER,
  VITE_AWS_ROLE_ARN_CONFIG_SCHEMA_READER,
  VITE_AWS_ROLE_ARN_CONFIG_SCHEMA_WRITER,
  VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_READER,
  VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_WRITER,
  VITE_AWS_ROLE_ARN_TERMINAL_SCHEMA_READER,
  VITE_AWS_ROLE_ARN_TERMINAL_SCHEMA_WRITER,
} from 'common/constants/env.constants.ts';

export const AWS_ROLES = {
  // Terminals
  TerminalsSchemaReader: VITE_AWS_ROLE_ARN_TERMINAL_SCHEMA_READER,
  TerminalsSchemaWriter: VITE_AWS_ROLE_ARN_TERMINAL_SCHEMA_WRITER,
  TerminalsEntryReader: VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_READER,
  TerminalsEntryWriter: VITE_AWS_ROLE_ARN_TERMINAL_ENTRY_WRITER,

  // Configs
  ConfigsSchemaReader: VITE_AWS_ROLE_ARN_CONFIG_SCHEMA_READER,
  ConfigsSchemaWriter: VITE_AWS_ROLE_ARN_CONFIG_SCHEMA_WRITER,
  ConfigsEntryReader: VITE_AWS_ROLE_ARN_CONFIG_ENTRY_READER,
  ConfigsEntryWriter: VITE_AWS_ROLE_ARN_CONFIG_ENTRY_WRITER,
} as const;

// Terminals roles
const AWS_ROLES_TERMINALS = {
  [AWS_ROLES.TerminalsSchemaReader]: { value: 'TerminalsSchemaReader', label: 'Read terminal groups' },
  [AWS_ROLES.TerminalsSchemaWriter]: { value: 'TerminalsSchemaWriter', label: 'Modify terminal groups' },
  [AWS_ROLES.TerminalsEntryReader]: { value: 'TerminalsEntryReader', label: 'Read terminals entries' },
  [AWS_ROLES.TerminalsEntryWriter]: { value: 'TerminalsEntryWriter', label: 'Modify terminal entries' },
};
// Configs roles
const AWS_ROLES_CONFIGS = {
  [AWS_ROLES.ConfigsSchemaReader]: { value: 'ConfigsSchemaReader', label: 'Read config groups' },
  [AWS_ROLES.ConfigsSchemaWriter]: { value: 'ConfigsSchemaWriter', label: 'Modify config groups' },
  [AWS_ROLES.ConfigsEntryReader]: { value: 'ConfigsEntryReader', label: 'Read configs entries' },
  [AWS_ROLES.ConfigsEntryWriter]: { value: 'ConfigsEntryWriter', label: 'Modify config entries' },
};

export const AWS_ROLES_MAPPING = { ...AWS_ROLES_TERMINALS, ...AWS_ROLES_CONFIGS };
