import { ReactElement, useEffect } from 'react';
import { useAtom } from 'jotai';
import { Outlet } from 'react-router-dom';
import { errorAtom } from 'common/atoms/error.atom.tsx';
import Alert from 'common/components/alert/alert.component.tsx';

const GeneralLayout = (): ReactElement => {
  const [errorData, setErrorData] = useAtom(errorAtom);

  useEffect(() => {
    if (!errorData.durationOff && errorData.message) {
      const duration = 5000;

      // Timer to clear the message after a specified duration
      const timer = setTimeout(() => {
        const clearedErrorData = { message: '', description: '', action: <div /> };
        setErrorData(clearedErrorData);
      }, duration);

      // Clearing the timer on unmount
      return (): void => {
        clearTimeout(timer);
      };
    }

    return undefined;
  }, [errorData.message, errorData.durationOff, setErrorData]);

  return (
    <>
      {errorData.message && (
        <Alert
          message={errorData.message}
          description={errorData.description || ''}
          type="error"
          className="custom-error-banner"
          showIcon
          banner
          closable
          onClose={() => {
            setErrorData({
              message: '',
              description: '',
              action: <div />,
            });
          }}
          action={errorData.action}
        />
      )}
      <Outlet />
    </>
  );
};

export default GeneralLayout;
