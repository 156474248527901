import { ReactElement, useEffect, useState } from 'react';
import { SSMClient } from '@aws-sdk/client-ssm';
import { Table, Button, Space } from 'antd';
import { Link } from 'react-router-dom';
import { ENV_AWS_CONFIGS_ROOT } from 'common/constants/env.constants.ts';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';
import useErrorHandlerHook from 'common/hooks/useErrorHandler.hook.tsx';
import { useAwsDescribeParametersQuery, useAwsInitConfigsGroupReaderQuery } from 'domains/aws/queries/aws.query.ts';
import AddEditConfigurationsGroupDrawer from 'pages/configurations-group/drawer/add-edit-configurations-group.drawer.tsx';
import { EDrawerType } from 'pages/configurations-group/enums/configurations-groups.enums.ts';
import {
  IConfigGroupDrawerData,
  IConfigGroupTableRowRecord,
} from 'pages/configurations-group/interfaces/configurations-groups.interfaces.ts';
import ConfigurationGroupSearch from 'pages/configurations-group/searchFilter/configuration-group-search.component.tsx';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';

const ConfigurationsGroupsPage = (): ReactElement => {
  const [configsGroupDrawerData, setConfigGroupDrawerData] = useState<IConfigGroupDrawerData | Record<string, never>>(
    {},
  );
  const [filterGroupNameValue, setFilterGroupNameValue] = useState<string>('');
  const token = localStorage.getItem(ELocalStorage.Token) ?? '';
  const { errorHandler } = useErrorHandlerHook();

  const {
    data: awsInitGroupReaderData,
    isLoading: awsInitGroupReaderLoading,
    isError: isAwsInitGroupReaderError,
    error: awsInitGroupReaderError,
  } = useAwsInitConfigsGroupReaderQuery(token, {
    enabled: !!token,
  });

  const ssmClient = awsInitGroupReaderData?.ssmClient as unknown as SSMClient;

  const {
    data: awsDescribeParametersData,
    isLoading: awsDescribeParametersLoading,
    isError: isAwsDescribeParametersError,
    error: awsDescribeParametersError,
  } = useAwsDescribeParametersQuery(
    ssmClient,
    {
      ParameterFilters: [
        {
          Values: [`${ENV_AWS_CONFIGS_ROOT}/schemas`],
          Key: 'Path',
          Option: 'Recursive',
        },
      ],
    },
    {
      enabled: !!Object.keys(awsInitGroupReaderData ?? {}).length,
    },
  );

  const isTableDataLoading = awsInitGroupReaderLoading || awsDescribeParametersLoading;
  const isTableDataHasError = isAwsInitGroupReaderError || isAwsDescribeParametersError;
  const tableDataError = awsInitGroupReaderError ?? awsDescribeParametersError;

  useEffect(() => {
    if (isTableDataHasError && tableDataError) {
      errorHandler(tableDataError);
    }
  }, [errorHandler, isTableDataHasError, tableDataError]);

  const tableData = awsDescribeParametersData
    ?.map((group) => {
      const groupName = group.Name?.split('/').slice(-2)[0];

      return {
        groupName,
        key: group.ARN,
      };
    })
    .filter((element) => element.groupName?.toLowerCase().includes(filterGroupNameValue));

  return (
    <>
      <ConfigurationGroupSearch
        setFilterGroupNameValue={setFilterGroupNameValue}
        setConfigGroupDrawerData={setConfigGroupDrawerData}
      />
      <Table dataSource={tableData} loading={isTableDataLoading} pagination={false}>
        <Table.Column
          title="Group name"
          dataIndex="groupName"
          key="groupName"
          width="100%"
          render={(groupName) => (
            <Link to={`${ROUTES_MAPPING.PROTECTED.CONFIGURATIONS}/${groupName as string}`}>{groupName}</Link>
          )}
        />
        <Table.Column
          key="action"
          render={(_, record: IConfigGroupTableRowRecord) => (
            <Space size="middle">
              <Button
                onClick={() => {
                  setConfigGroupDrawerData({
                    type: EDrawerType.Edit,
                    record,
                  });
                }}
              >
                Edit
              </Button>
            </Space>
          )}
        />
      </Table>
      <AddEditConfigurationsGroupDrawer
        width="70%"
        open={!!Object.keys(configsGroupDrawerData).length}
        data={configsGroupDrawerData}
        onClose={() => {
          setConfigGroupDrawerData({});
        }}
      />
    </>
  );
};

export default ConfigurationsGroupsPage;
