import { ReactElement } from 'react';
import { GroupOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';

interface IProps {
  path: string;
  groupName: string | undefined;
  pageTitle: string;
}

const Breadcrumbs = (props: IProps): ReactElement => {
  const { path, groupName, pageTitle } = props;

  const navigate = useNavigate();

  return (
    <Breadcrumb
      style={{
        paddingTop: 24,
        paddingBottom: 24,
      }}
      items={[
        {
          href: '',
          onClick: (e): void => {
            e.preventDefault();
            navigate(path);
          },
          title: (
            <>
              <GroupOutlined />
              <span>{pageTitle}</span>
            </>
          ),
        },
        {
          title: groupName,
        },
      ]}
    />
  );
};

export default Breadcrumbs;
