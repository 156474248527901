import {
  DescribeParametersCommandInput,
  GetParameterCommandInput,
  GetParameterCommandOutput,
  PutParameterCommandInput,
  PutParameterCommandOutput,
  SSMClient,
  ParameterMetadata,
} from '@aws-sdk/client-ssm';
import { useQuery, UseQueryResult, useMutation, UseMutationResult } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { IAwsPrerequisitesServiceRes } from 'domains/aws/interfaces/aws.interfaces.ts';
import { awsPutParameterCommandService } from 'domains/aws/services/aws.service.ts';

export const useAwsInitGroupReaderQuery = (
  token: string,
  options?: Omit<UseQueryOptions<IAwsPrerequisitesServiceRes>, 'queryKey'>,
): UseQueryResult<IAwsPrerequisitesServiceRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AwsQueryKeys].awsInitGroupReader(
      token,
    ) as unknown as UseQueryOptions<IAwsPrerequisitesServiceRes>),
    gcTime: 5 * 60 * 1000,
  });

export const useAwsInitGroupWriterQuery = (
  token: string,
  options?: Omit<UseQueryOptions<IAwsPrerequisitesServiceRes>, 'queryKey'>,
): UseQueryResult<IAwsPrerequisitesServiceRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AwsQueryKeys].awsInitGroupWriter(
      token,
    ) as unknown as UseQueryOptions<IAwsPrerequisitesServiceRes>),
    gcTime: 5 * 60 * 1000,
  });

export const useAwsInitEntryReaderQuery = (
  token: string,
  options?: Omit<UseQueryOptions<IAwsPrerequisitesServiceRes>, 'queryKey'>,
): UseQueryResult<IAwsPrerequisitesServiceRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AwsQueryKeys].awsInitEntryReader(
      token,
    ) as unknown as UseQueryOptions<IAwsPrerequisitesServiceRes>),
    gcTime: 5 * 60 * 1000,
  });

export const useAwsInitEntryWriterQuery = (
  token: string,
  options?: Omit<UseQueryOptions<IAwsPrerequisitesServiceRes>, 'queryKey'>,
): UseQueryResult<IAwsPrerequisitesServiceRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AwsQueryKeys].awsInitEntryWriter(
      token,
    ) as unknown as UseQueryOptions<IAwsPrerequisitesServiceRes>),
    gcTime: 5 * 60 * 1000,
  });

export const useAwsDescribeParametersQuery = (
  ssmClient: SSMClient,
  input: DescribeParametersCommandInput,
  options?: Omit<UseQueryOptions<ParameterMetadata[]>, 'queryKey'>,
): UseQueryResult<ParameterMetadata[]> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AwsQueryKeys].awsDescribeParameters(ssmClient, input) as unknown as UseQueryOptions<
      ParameterMetadata[]
    >),
    gcTime: 5 * 60 * 1000,
  });

export const useAwsGetParameterCommandQuery = (
  ssmClient: SSMClient,
  input: GetParameterCommandInput,
  options?: Omit<UseQueryOptions<GetParameterCommandOutput>, 'queryKey'>,
): UseQueryResult<GetParameterCommandOutput> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AwsQueryKeys].awsGetParameterCommand(
      ssmClient,
      input,
    ) as unknown as UseQueryOptions<GetParameterCommandOutput>),
    gcTime: 5 * 60 * 1000,
  });

export const useAwsPutParameterCommandMutation = (): UseMutationResult<
  PutParameterCommandOutput,
  Error,
  {
    input: PutParameterCommandInput;
    ssmClient: SSMClient;
  }
> =>
  useMutation({
    mutationFn: (data: { input: PutParameterCommandInput; ssmClient: SSMClient }) =>
      awsPutParameterCommandService(data.ssmClient, data.input),
  });

// Configurations requests
export const useAwsInitConfigsGroupWriterQuery = (
  token: string,
  options?: Omit<UseQueryOptions<IAwsPrerequisitesServiceRes>, 'queryKey'>,
): UseQueryResult<IAwsPrerequisitesServiceRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AwsQueryKeys].awsInitConfigsGroupWriter(
      token,
    ) as unknown as UseQueryOptions<IAwsPrerequisitesServiceRes>),
    gcTime: 5 * 60 * 1000,
  });

export const useAwsInitConfigsGroupReaderQuery = (
  token: string,
  options?: Omit<UseQueryOptions<IAwsPrerequisitesServiceRes>, 'queryKey'>,
): UseQueryResult<IAwsPrerequisitesServiceRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AwsQueryKeys].awsInitConfigsGroupReader(
      token,
    ) as unknown as UseQueryOptions<IAwsPrerequisitesServiceRes>),
    gcTime: 5 * 60 * 1000,
  });

export const useAwsInitConfigsEntryReaderQuery = (
  token: string,
  options?: Omit<UseQueryOptions<IAwsPrerequisitesServiceRes>, 'queryKey'>,
): UseQueryResult<IAwsPrerequisitesServiceRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AwsQueryKeys].awsInitConfigsEntryReader(
      token,
    ) as unknown as UseQueryOptions<IAwsPrerequisitesServiceRes>),
    gcTime: 5 * 60 * 1000,
  });

export const useAwsInitConfigsEntryWriterQuery = (
  token: string,
  options?: Omit<UseQueryOptions<IAwsPrerequisitesServiceRes>, 'queryKey'>,
): UseQueryResult<IAwsPrerequisitesServiceRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AwsQueryKeys].awsInitConfigsEntryWriter(
      token,
    ) as unknown as UseQueryOptions<IAwsPrerequisitesServiceRes>),
    gcTime: 5 * 60 * 1000,
  });
