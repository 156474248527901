import { ReactNode, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { ParameterTier, ParameterType, SSMClient } from '@aws-sdk/client-ssm';
import { Button, Card, Checkbox, Drawer, DrawerProps, Flex, Form, Input, Select, Typography } from 'antd';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { ENV_AWS_CONFIGS_ROOT, ENV_AWS_KMS_KEY } from 'common/constants/env.constants.ts';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { generateScheme } from 'common/helpers/sheme.helper.ts';
import { isObjectHasKeys } from 'common/helpers/validator.helper.ts';
import useErrorHandlerHook from 'common/hooks/useErrorHandler.hook.tsx';
import {
  useAwsGetParameterCommandQuery,
  useAwsInitConfigsGroupWriterQuery,
  useAwsPutParameterCommandMutation,
} from 'domains/aws/queries/aws.query.ts';
import {
  ETerminalFields,
  ETerminalFieldsTypeTypes,
} from 'domains/group-terminal-schema/enums/group-terminal-schema.enum.ts';
import {
  ISchema,
  ITerminalFieldsSet,
} from 'domains/group-terminal-schema/interfaces/group-terminal-schema.interface.ts';
import { EDrawerType } from '../../terminal-groups/enums/terminal-groups.enums.ts';
import { ITerminalGroupDrawerData } from '../../terminal-groups/interfaces/terminal-groups.interfaces.ts';

enum EFormMainItems {
  GroupName = 'groupName',
  Description = 'description',
  Fields = 'fields',
}

enum EFormFieldsName {
  Label = 'Label',
}

interface IFormFields {
  [EFormMainItems.GroupName]: string;
  [EFormMainItems.Description]: string;
  [EFormMainItems.Fields]: ITerminalFieldsSet[] | undefined | [] | [undefined];
}

interface IProps extends DrawerProps {
  data: ITerminalGroupDrawerData | Record<string, never>;
  onClose: () => void;
}

interface IFormFieldsList {
  fields: (
    | {
        description?: string;
        name?: EFormFieldsName;
        required?: boolean;
        type?: string;
      }
    | undefined
  )[];
}

const AddEditConfigurationsGroupDrawer = (props: IProps): ReactNode => {
  const {
    onClose,
    open,
    data: { type: drawerType, record },
  } = props;

  const [form] = Form.useForm();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [schema, setSchema] = useState<ISchema | Record<string, never>>({});
  const token = localStorage.getItem(ELocalStorage.Token) ?? '';
  const { errorHandler } = useErrorHandlerHook();

  const {
    data: awsInitGroupWriterData,
    isLoading: isAwsInitGroupWriterDataLoading,
    isError: isAwsInitGroupWriterDataError,
    error: awsInitGroupWriterDataError,
  } = useAwsInitConfigsGroupWriterQuery(token, {
    enabled: !!token && open,
  });

  const ssmClient = awsInitGroupWriterData?.ssmClient as unknown as SSMClient;
  const {
    data: awsGetJsonData,
    isLoading: isAwsGetJsonDataLoading,
    isError: isAwsGetJsonDataError,
    error: awsGetJsonDataError,
  } = useAwsGetParameterCommandQuery(
    ssmClient,
    {
      Name: `${ENV_AWS_CONFIGS_ROOT}/schemas/${record?.groupName ?? '<!!!unknown-group-name!!!>'}/_configs_schema.json`,
      WithDecryption: true,
    },
    {
      enabled: isObjectHasKeys(ssmClient) && drawerType === EDrawerType.Edit && open,
    },
  );

  const isDrawerDataLoading = isAwsInitGroupWriterDataLoading || isAwsGetJsonDataLoading;
  const isDrawerDataHasError = isAwsInitGroupWriterDataError || isAwsGetJsonDataError;
  const drawerDataError = awsInitGroupWriterDataError ?? awsGetJsonDataError;

  useEffect(() => {
    if (isDrawerDataHasError && drawerDataError) {
      errorHandler(drawerDataError);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerDataHasError]);

  const { mutateAsync: mutateAsyncAwsPutParameterCommandMutation } = useAwsPutParameterCommandMutation();

  const schemaGroupFormatConverter = (data?: IFormFields): void => {
    const groupName = data?.groupName ?? '';
    const description = data?.description ?? '';

    const properties = (data?.fields ?? [undefined]).reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        ...(currentValue && Object.keys(currentValue).length
          ? {
              [currentValue.name]: {
                description: currentValue[ETerminalFields.Description],
                type: currentValue[ETerminalFields.Type],
                required: currentValue[ETerminalFields.Required],
              },
            }
          : null),
      }),
      {},
    );

    const required = Object.entries<ITerminalFieldsSet>(properties)
      .map(([key, value]) => (value.required ? key : null))
      .filter((name) => !!name);

    const schema = generateScheme(groupName, description, properties, required);
    setSchema(schema);
  };

  useEffect(() => {
    if (drawerType === EDrawerType.Edit && awsGetJsonData?.Parameter?.Value) {
      const schemaData = JSON.parse(awsGetJsonData.Parameter.Value) as ISchema;
      const { title, description, properties } = schemaData;

      const fields = Object.entries(properties).map(([key, data]) => ({
        description: data[ETerminalFields.Description],
        name: key,
        required: data[ETerminalFields.Required],
        type: data[ETerminalFields.Type],
      }));

      form.setFields([
        {
          name: EFormMainItems.GroupName,
          value: title,
        },
        {
          name: EFormMainItems.Description,
          value: description,
        },
        {
          name: EFormMainItems.Fields,
          value: fields,
        },
      ]);

      setSchema(schemaData);
    } else {
      const labelField = {
        [ETerminalFields.Description]: 'Terminal ID. Used for search, logging and metrics purposes.',
        [ETerminalFields.Name]: EFormFieldsName.Label,
        [ETerminalFields.Required]: false,
        [ETerminalFields.Type]: ETerminalFieldsTypeTypes.String,
      };

      const schemaData = {
        [EFormMainItems.GroupName]: '',
        [EFormMainItems.Description]: '',
        [EFormMainItems.Fields]: [labelField],
      };

      schemaGroupFormatConverter(schemaData);
    }
  }, [record, drawerType, form, awsGetJsonData?.Parameter?.Value]);

  const onValuesChangeFormHandler = (_: Record<EFormMainItems, string>, allValues: IFormFields): void => {
    schemaGroupFormatConverter(allValues);
  };

  const onSubmitFormHandler = async ({ groupName }: IFormFields): Promise<void> => {
    try {
      setIsFormSubmitting(true);

      const ssmClient = awsInitGroupWriterData?.ssmClient as unknown as SSMClient;
      await mutateAsyncAwsPutParameterCommandMutation({
        ssmClient,
        input: {
          Name: `${ENV_AWS_CONFIGS_ROOT}/schemas/${groupName}/_configs_schema.json`,
          Value: JSON.stringify(schema),
          Type: ParameterType.SECURE_STRING,
          Tier: ParameterTier.ADVANCED,
          Overwrite: drawerType === EDrawerType.Edit,
          KeyId: ENV_AWS_KMS_KEY,
        },
      });

      await queryClient.invalidateQueries({
        queryKey: queryKeysClient[EQueryKeys.AwsQueryKeys].awsDescribeParameters._def,
      });

      setIsFormSubmitting(false);
      onClose();
    } catch (error) {
      errorHandler(error as Error);
      setIsFormSubmitting(false);
      onClose();
    }
  };

  return (
    <Drawer
      {...props}
      destroyOnClose
      afterOpenChange={(open) => {
        if (!open) {
          form.resetFields();
        }
      }}
      loading={isDrawerDataLoading}
    >
      <Form layout="vertical" form={form} onFinish={onSubmitFormHandler} onValuesChange={onValuesChangeFormHandler}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 24,
          }}
        >
          <Typography.Paragraph style={{ flex: 1 }}>
            <pre>{JSON.stringify(schema, null, 2)}</pre>
          </Typography.Paragraph>

          <div style={{ flex: 1 }}>
            <Form.Item
              label="Group Name"
              name={EFormMainItems.GroupName}
              rules={[{ required: true, message: 'Please input group name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name={EFormMainItems.Description}
              rules={[{ required: true, message: 'Please input group description' }]}
            >
              <Input />
            </Form.Item>

            <Form.List name={EFormMainItems.Fields}>
              {(fields, { add, remove }) => (
                <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                  {fields.map((field) => {
                    const fieldsData = form.getFieldsValue([EFormMainItems.Fields]) as IFormFieldsList;

                    const fieldName = fieldsData.fields[field.key]?.name;

                    return (
                      <Card
                        size="small"
                        key={field.key}
                        {...(fieldName !== EFormFieldsName.Label
                          ? {
                              extra: (
                                <CloseOutlined
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                              ),
                            }
                          : {})}
                      >
                        <Form.Item
                          label="Name"
                          name={[field.name, ETerminalFields.Name]}
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                            {
                              validator: (_, value: string | undefined): Promise<void> => {
                                let count = 0;

                                fieldsData.fields.forEach((field) => {
                                  if (field?.[ETerminalFields.Name] === value) {
                                    count += 1;
                                  }
                                });

                                if (count >= 2) {
                                  return Promise.reject(
                                    Error('You trying to enter the name which have already existed'),
                                  );
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input disabled={fieldName === EFormFieldsName.Label && field.key === 0} />
                        </Form.Item>

                        <Form.Item
                          label="Description"
                          name={[field.name, ETerminalFields.Description]}
                          rules={[{ required: true, message: 'Field is required' }]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Type"
                          name={[field.name, ETerminalFields.Type]}
                          rules={[{ required: true, message: 'Field is required' }]}
                        >
                          <Select placeholder="Select type">
                            <Select.Option value={ETerminalFieldsTypeTypes.String}>
                              {ETerminalFieldsTypeTypes.String}
                            </Select.Option>
                            <Select.Option value={ETerminalFieldsTypeTypes.Integer}>
                              {ETerminalFieldsTypeTypes.Integer}
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item name={[field.name, ETerminalFields.Required]} valuePropName="checked">
                          <Checkbox>Is field required?</Checkbox>
                        </Form.Item>
                      </Card>
                    );
                  })}

                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                  >
                    + Add Item
                  </Button>
                </div>
              )}
            </Form.List>
          </div>
        </div>
        <Flex
          justify="flex-end"
          gap={24}
          style={{
            paddingBottom: 24,
            paddingTop: 24,
          }}
        >
          <Button key="close" onClick={onClose}>
            Cancel
          </Button>
          <Button key="submit" type="primary" loading={isFormSubmitting} onClick={form.submit}>
            Submit
          </Button>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default AddEditConfigurationsGroupDrawer;
