import { createBrowserRouter, createRoutesFromElements, Route, Navigate } from 'react-router-dom';
import ErrorBoundary from 'common/components/error-boundary/error-boundary.component.tsx';
import GeneralLayout from 'common/layouts/general-layout/general.layout.tsx';
import ProtectedRouteMainLayout from 'common/layouts/protected-route-main-layout/protected-route-main.layout.tsx';
import ConfigurationsGroupsPage from 'pages/configurations-group/configurations-groups.page.tsx';
import GroupConfigurationsPage from 'pages/group-configurations/group-configurations.page.tsx';
import GroupTerminalsPage from 'pages/group-terminals/group-terminals.page.tsx';
import SignInPage from 'pages/sign-in/sign-in.page.tsx';
import TerminalGroupsPage from 'pages/terminal-groups/terminal-groups.page.tsx';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import ProtectedRouteGuard from './guards/protected-route.guard.tsx';
import PublicRouteGuard from './guards/public-route.guard.tsx';

const routes = (
  <Route
    path={ROUTES_MAPPING.ROOT}
    // This option allows you to unlock the React Query cache for a second request
    shouldRevalidate={({ currentUrl }): boolean => currentUrl.pathname === ROUTES_MAPPING.PUBLIC.SIGN_IN}
    errorElement={<ErrorBoundary />}
    element={<GeneralLayout />}
  >
    <Route index element={<Navigate to={ROUTES_MAPPING.PUBLIC.SIGN_IN} />} />

    {/* Public routes */}
    <Route element={<PublicRouteGuard />}>
      <Route path={ROUTES_MAPPING.PUBLIC.SIGN_IN} element={<SignInPage />} />
    </Route>

    {/* Protected routes */}
    <Route element={<ProtectedRouteGuard />}>
      <Route element={<ProtectedRouteMainLayout />}>
        <Route path={ROUTES_MAPPING.PROTECTED.TERMINAL_GROUPS}>
          <Route index element={<TerminalGroupsPage />} />
          <Route path=":groupName" element={<GroupTerminalsPage />} />
        </Route>

        <Route path={ROUTES_MAPPING.PROTECTED.CONFIGURATIONS}>
          <Route index element={<ConfigurationsGroupsPage />} />
          <Route path=":groupName" element={<GroupConfigurationsPage />} />
        </Route>
      </Route>
    </Route>

    {/* <Route element={<MainLayout />}> */}
    {/*   <Route path={ROUTES_MAPPING.ERROR} element={<ErrorPage />} /> */}
    {/* </Route> */}

    {/* <Route path={ROUTES_MAPPING.NO_MATCH} element={<MainLayout />}> */}
    {/*   <Route index element={<NoMatchPage />} /> */}
    {/* </Route> */}
    {/* <Route path="*" element={<Navigate to={ROUTES_MAPPING.NO_MATCH} />} /> */}
  </Route>
);
const routerTree = createBrowserRouter(createRoutesFromElements(routes));

export default routerTree;
