import { ISchema } from 'domains/group-terminal-schema/interfaces/group-terminal-schema.interface.ts';

export const generateScheme = (
  groupName: string,
  description: string,
  properties: Record<string, unknown>,
  required: (string | null)[] | undefined,
): ISchema =>
  ({
    $schema: 'https://json-schema.org/draft/2020-12/schema',
    $id: 'https://example.com/product.schema.json',
    title: groupName,
    description,
    type: 'object',
    properties,
    required,
  }) as ISchema;
