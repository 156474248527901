import { ReactElement, useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined, GroupOutlined } from '@ant-design/icons';
import { Button, Layout, theme, Avatar, Menu, Typography } from 'antd';
import { useSetAtom } from 'jotai/index';
import { Outlet, useNavigate } from 'react-router-dom';
import { errorAtom } from 'common/atoms/error.atom.tsx';
import { ELocalStorage } from 'common/enums/local-storage.enums.ts';
import {
  SHeaderUserContainer,
  SMenuWrapper,
} from 'common/layouts/protected-route-main-layout/protected-route-main.layout.styles.ts';
import { IAuthUserData } from 'domains/auth/interfaces/auth.interfaces.ts';
import { authLogoutService } from 'domains/auth/services/auth.services.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';

const { Header, Sider, Content } = Layout;

const ProtectedRouteMainLayout = (): ReactElement => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const setErrorData = useSetAtom(errorAtom);
  const menuItem = document.location.pathname.split('/')[1];
  const subMenuItem = document.location.pathname.split('/')[2];
  const activeMenuPath = [`/${menuItem}`, `/${menuItem}/${subMenuItem}`];
  const userData = JSON.parse(localStorage.getItem(ELocalStorage.User) ?? '') as IAuthUserData;

  const menuItems = [
    {
      key: ROUTES_MAPPING.PROTECTED.TERMINAL_GROUPS,
      icon: <GroupOutlined />,
      label: 'Terminal Groups',
    },
    {
      key: ROUTES_MAPPING.PROTECTED.CONFIGURATIONS,
      icon: <GroupOutlined />,
      label: 'Configurations',
    },
  ];

  const menuClickHandler = ({ key }: { key: string }): void => {
    navigate(key);
  };

  const logoutHandler = async (): Promise<void> => {
    try {
      await authLogoutService();
      setErrorData({
        message: '',
        description: '',
        action: <div />,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          padding: 10,
        }}
      >
        <SMenuWrapper>
          <Menu
            theme="dark"
            mode="vertical"
            defaultSelectedKeys={[ROUTES_MAPPING.PROTECTED.TERMINAL_GROUPS]}
            selectedKeys={activeMenuPath}
            items={menuItems}
            onClick={menuClickHandler}
          />
        </SMenuWrapper>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer, display: 'flex' }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <SHeaderUserContainer>
            <Typography.Text strong>{userData.name}</Typography.Text>
            <Avatar size={40} src={userData.picture} />
            <Button onClick={logoutHandler} icon={<LogoutOutlined />}>
              Logout
            </Button>
          </SHeaderUserContainer>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default ProtectedRouteMainLayout;
